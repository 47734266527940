/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import {Uuid} from "../../components/util/uuid";

import creativeCopyTemplate from './creative-from-line-item-copy-modal.html';
import cpmFeesTemplate from './line-item-cpm-fees-modal.html';
import creativePreviewTemplate from '../creative/ad/creative-preview-modal.html';
import targetingTemplate from '../targeting/base-targeting-editor-modal.html';
import mpProductTemplate from './mp-product-modal.html';

import rateLimitComponent from '../common/controller/rate-limit-component';
import standardFormModule from '../common/standard-form-module';
import timeShifter from '../../components/util/time-shifter';
import targetingModule from '../targeting/targeting-module';
import creativePreviewController from '../creative/ad/creative-preview-controller';
import {adnFormHelper} from "../common/controller/form-helper";
import {filterOutUnwantedTargets, isBidTooHigh, lineItemCommons, officialHighImpactSimple, officialProductSimple, productCreativeTag, simpleMarketplaceProducts, setLineItemDefaults} from "./line-item-commons";
import {creativeBulkAction, creativeCopyInBulk, postFillGenerateFormatFees} from "../creative/creative-commons";
import {ADN_TARGETING_TYPES, getTargetingType} from "../targeting/targeting-constants";
import {TargetingHelper} from "../targeting/targeting-helper";
import {MoneyHelper} from "../../components/util/money";
import creativeWeightingTemplate from './creative-weighting.html';
import lineItemCreativeListTemplate from './line-item-creative-list.html';
import creativeDeliveryTemplate from './creative-delivery.html';
import lineItemDealIdTemplate from './line-item-deal-id.html';
import lineItemEdit2 from './line-item-edit-2.html';
import lineItemIabCategory from './line-item-iab-category.html';
import conversionTrackingTemplate from './conversion-tracking.html';
import {SubmitCalls} from "../common/directives/submit-calls";
import {IAB_TAXONOMY} from "../../components/util/iab-taxonomy";
import {calculateNetCpm, deriveGrossTargetCpm} from "./fees-calculator";
import {LocalNetworkInfo} from "../../components/session/local-network-info";
import {CpaDefaults} from "./cpa-defaults";
import {defaultCurrencyDeriver} from "./currency-default-calculator";
import orderBudgetsTemplate from "../order/order-budget-modal.html";
import {updateBudgets} from "../order/order-controller";
import budgetFieldsTemplate from "../order/budget-fields.html";

const objToStatRef = {
  IMPRESSION: {
    stat: 'impressions',
    trans: 'impressions'
  },
  CLICK: {
    stat: 'clicks',
    trans: 'clicks'
  },
  VISIBLE_IMPRESSION: {
    stat: 'visibleImpressions',
    trans: 'visibles'
  },
  VIEWABLE_IMPRESSION: {
    stat: 'viewableImpressions',
    trans: 'viewables'
  },
  RENDERED_IMPRESSION: {
    stat: 'renderedImpressions',
    trans: 'rendered'
  }
};

const MODULE_NAME = 'line-item-controller';

export function getMatchingFee(fees, referenceCurrency) {
  let foundFee = _.find(_.isArray(fees) ? fees : [], function(amount) {
    return amount.currency === referenceCurrency;
  });
  if (!foundFee && _.isArray(fees) && fees.length > 0) {
    // default to first entry
    foundFee = fees[0];
  }
  return foundFee;
}

export function simplifyFee(LocalNetworkProfile, referenceCurrency, f) {
  if (referenceCurrency && (referenceCurrency === _.get(f, ['monetary', 'currency']) || getMatchingFee(_.get(f, 'multiCurrencyMonetaryAmounts'), referenceCurrency))) {
    const cff = _.cloneDeep(f);
    cff.name = LocalNetworkProfile.getNetworkTranslations("creativeFormatFee");
    cff.id = "creative-format-fee";
    if (cff.type === 'MULTI_CURRENCY_MONETARY') {
      const matchingFee = getMatchingFee(f.multiCurrencyMonetaryAmounts, referenceCurrency);
      if (matchingFee) {
        cff.monetary = matchingFee;
      }
    }
    return cff;
  }
}

angular.module(MODULE_NAME, [standardFormModule, rateLimitComponent, timeShifter, targetingModule, creativePreviewController])

  .controller('LineItemCtrl', function(model, readOnly, $state, full, viewType, adnInactiveTargets, LocalNetworkProfile, $location, LineItem, localUserProfile, $scope, $rootScope, burnRatesResource, FirstPartyAudience, MarketplaceProductOptions, NetworkData, $interpolate, $log, $templateCache, orderFromParam, advertiserFromParam, adnModalLoading, modelComms, adnListHelper, Team, adnStatFilter, $timeout, $q, $document, Rollbar, Order, MarketplaceProduct, LocalUserPermissions, lineItemView, statsResource, CustomEvent, dataViewResource, $filter, $translate, $stateParams, $uibModal, Creative, adnTimeShifter) {
    const ctrl = this,
      commons = lineItemCommons(ctrl, $translate, $filter, LocalNetworkProfile, LocalUserPermissions, adnTimeShifter);

    ctrl.isLiTemplate = model.resourceName === 'lineItemTemplate';
    ctrl.isNew = $stateParams.isNew;
    ctrl.perms = LocalUserPermissions.getAllVisPermissions();
    ctrl.skips = [];
    ctrl.mistakenBidEnabled = true;
    ctrl.isPureMarketplace = LocalNetworkProfile.isPureMarketplacePlatform();
    ctrl.conversionType = 'ADVERTISER';
    viewType = LocalNetworkProfile.isCalt() ? 2 : 1;
    ctrl.viewType = viewType;
    ctrl.allowedDeliveryCurves = ['FAST_AS_POSSIBLE', 'EVEN', 'CAPPED', 'FRONT_LOADED', 'CATCH_UP', 'GREEDY'];

    if (viewType === 2 && !model.order && !ctrl.isLiTemplate && !full) {
      $state.go('app.line-items.line-item', {id: model.id, viewType: viewType, full: true}, {inherit: false});
    }

    if (ctrl.isNew && orderFromParam) {
      model.order = _.cloneDeep(orderFromParam);
      $timeout(function() {
        // doing a timeout here because want to make sure everything has initiated
        ctrl.orderSelect.onSelect();
      }, 101);
    }
    if (ctrl.isNew && advertiserFromParam) {
      model.advertiser = _.cloneDeep(advertiserFromParam);
    }

    const originalCpm = _.get(model, ['bidSpecification', 'cpm']);
    const originalCpc = _.get(model, ['bidSpecification', 'cpc']);
    const originalCpa = _.get(model, ['bidSpecification', 'cpa', 'cost']);

    const axPublisherPermission = model.permission === 'AX_PUBLISHER';
    ctrl.isAxPublisher = axPublisherPermission;
    ctrl.dynamicBiddingAllowed = LocalNetworkProfile.isMarketplacePlatform();
    // We only show the option to bid on "attention" in the C-ALT network
    ctrl.showAttentionBiddingGoal = LocalNetworkProfile.getNetworkTag() === 'b7462';
    ctrl.userStateReview = 'APPROVED';
    ctrl.endPoint = axPublisherPermission ? 'approve' : 'save';
    commons.setSelfService();
    commons.setAxAdvertiser();
    ctrl.isSelfServiceOwn = LocalUserPermissions.isSelfServiceOwn();
    ctrl.allowsSecondPrice = false;
    ctrl.allowsDealId = (!ctrl.isAxPublisher && !ctrl.isAxAdvertiser) && ($location.host() === 'localhost' || _.includes(['b7462', '145b90', '1287', 'f', 'dev_network', '140f2f', '1a9cda'], LocalNetworkProfile.getNetworkTag()));
    $templateCache.put("lineItemEdit.html", lineItemEdit2);
    $templateCache.put("lineItemDealId.html", lineItemDealIdTemplate);
    $templateCache.put("creativeList.html", lineItemCreativeListTemplate);
    $templateCache.put("creativeDelivery.html", creativeDeliveryTemplate);
    $templateCache.put("creativeWeighting.html", creativeWeightingTemplate);
    $templateCache.put("conversionTracking.html", conversionTrackingTemplate);
    $templateCache.put("lineItemIabCategory.html", lineItemIabCategory);
    $templateCache.put("budgetList.html", budgetFieldsTemplate);
    ctrl.currencies = LocalNetworkProfile.get('availableCurrencies');
    ctrl.primaryNetworkCurrency = LocalNetworkProfile.get('defaultCurrency');
    ctrl.canLimitBurnRates = localUserProfile.canLimitBurnRates();
    ctrl.productSimples = [{id: 1, name: 'Programmatic', description: 'Deliver your ads programmatically with any of our publishers'}, {id: 2, name: 'Hosted', description: 'Host your ads with us and have them deliver elsewhere'}, {id: 3, name: 'Custom', description: 'Choose a specialised delivery option'}];
    ctrl.showFirstParty = ((LocalNetworkProfile.isPureMarketplacePlatform() || LocalNetworkProfile.getNetworkTag() === '1287') &&
        ($location.host() === 'localhost' || localUserProfile.get("username") === "adnuntius@adnuntius.com")) ||
        (LocalNetworkProfile.isPureMarketplacePlatform() && ctrl.isAxAdvertiser);
    ctrl.showBudgetPeriods = LocalNetworkProfile.isPureMarketplacePlatform() || LocalNetworkProfile.getNetworkTag() === '1287';
    const selectedFirstPartyAudienceIds = (model.firstPartyAudiences || []).map(firstPartyAudience => firstPartyAudience['id']);
    if (ctrl.showFirstParty) {
      FirstPartyAudience.query(function(page) {
        ctrl.firstPartyAudiences = page.results
          .filter(firstPartyAudience => firstPartyAudience.publishState === "APPROVED" || _.includes(selectedFirstPartyAudienceIds, firstPartyAudience['id']));
      });
    }

    function renameProduct(team) {
      const siteCountries = _.get(team, ['defaultTargeting', ADN_TARGETING_TYPES.siteCountry.targets, ADN_TARGETING_TYPES.siteCountry.apiProp]);
      if (_.isArray(siteCountries) && siteCountries.length === 1 && siteCountries[0] === 'DK') {
        if (_.get(ctrl.model, ['axProduct', 'id']) === 'pzlwjm3nmddkr3c3') {
          _.set(ctrl.model, ['axProduct', 'name'], 'Publisher Platform - Danmark');
        }
      }
    }

    function updateAvailableSmoothingOptions() {
      ctrl.smoothingOptions = [];
      ctrl.allSmoothingOptions = ['FAST_AS_POSSIBLE', 'EVEN', 'FRONT_LOADED', 'CAPPED', 'CATCH_UP', 'GREEDY'];
      const assessCurves = ctrl.showEverything  || ctrl.marketplaceResolved;
      let allowedDeliveryCurves = _.cloneDeep(ctrl.allowedDeliveryCurves);
      if (!allowedDeliveryCurves) {
        allowedDeliveryCurves = ['EVEN', 'FRONT_LOADED', 'CAPPED'];
      }
      if (assessCurves) {
        if (ctrl.model.deliveryCurve === 'FAST_AS_POSSIBLE' || allowedDeliveryCurves.includes('FAST_AS_POSSIBLE')) {
          ctrl.smoothingOptions.push('FAST_AS_POSSIBLE');
        }
        if (ctrl.model.deliveryCurve === 'EVEN' || allowedDeliveryCurves.includes('EVEN')) {
          ctrl.smoothingOptions.push('EVEN');
        }
        if (ctrl.model.deliveryCurve === 'FRONT_LOADED' || allowedDeliveryCurves.includes('FRONT_LOADED')) {
          ctrl.smoothingOptions.push('FRONT_LOADED');
        }
        if (ctrl.model.deliveryCurve === 'CAPPED' || allowedDeliveryCurves.includes('CAPPED')) {
          ctrl.smoothingOptions.push('CAPPED');
        }
        if (ctrl.model.deliveryCurve === 'CATCH_UP' || allowedDeliveryCurves.includes('CATCH_UP')) {
          ctrl.smoothingOptions.push('CATCH_UP');
        }
        if (ctrl.model.deliveryCurve === 'GREEDY' || allowedDeliveryCurves.includes('GREEDY')) {
          ctrl.smoothingOptions.push('GREEDY');
        }
      }
    }

    function defaultShowObjective() {
      ctrl.showObjectives = !axPublisherPermission || ctrl.model.objectives;
    }

    ctrl.rateLimitTypes = ['IMPRESSION', 'RENDERED_IMPRESSION', 'VISIBLE_IMPRESSION', 'VIEWABLE_IMPRESSION', 'CLICK', 'BUDGET'];
    let mpQuery;

    function workOutMinMaxCpm(theProduct) {
      ctrl.model.minimumCpm = _.get(theProduct, ['minimumCpm', 'amount']) ? deriveGrossTargetCpm(ctrl.uiFees.fees, theProduct.minimumCpm) : {amount: 0, currency: ctrl.primaryNetworkCurrency};
      ctrl.model.maximumCpm = _.get(theProduct, ['maximumCpc', 'amount']) ? deriveGrossTargetCpm(ctrl.uiFees.fees, theProduct.maximumCpm) : {amount: 0, currency: ctrl.primaryNetworkCurrency};
    }

    function updateProductSimpleSelection() {
      const productId = _.get(ctrl, ['model', 'axProduct', 'id']);
      ctrl.selectedDelivery = productId === productCreativeTag ? 2 : (productId === officialProductSimple || productId === officialHighImpactSimple) ? 1 : 3;
      const highImpactSelected = officialHighImpactSimple === productId;
      ctrl.selectedCountries = [];
      _.forEach(_.get(ctrl, ['model', 'targeting', ADN_TARGETING_TYPES.siteCountry.targets, ADN_TARGETING_TYPES.siteCountry.apiProp]), function(countryCode) {
        ctrl.selectedCountries.push(countryCode);
      });
      ctrl.selectedCreativeType = highImpactSelected ? 2 : 1;
      if (ctrl.selectedDelivery === 2) {
        ctrl.productString = "Hosted";
        ctrl.showSimpleOptions = true;
        return;
      }
      if (simpleMarketplaceProducts.indexOf(productId) > -1) {
        ctrl.productString = highImpactSelected ? "High Impact " : "";
        const countryInfo = _.map(ctrl.selectedCountries, function(country) {
          return $filter('country')(country);
        }).join(", ");
        ctrl.productString += "Programmatic";
        if (countryInfo) {
          ctrl.productString += " for " + countryInfo;
        }
      } else {
        ctrl.productString = _.get(ctrl, ['model', 'axProduct', 'name']);
      }

      const hasAtLeastOne = _.find(ctrl.marketplaceProducts, function(p) {
        return simpleMarketplaceProducts.indexOf(p.id) > -1;
      });
      ctrl.showSimpleOptions = !!hasAtLeastOne;
    }
    function updateProductFromSimpleSelection() {
      if (ctrl.selectedDelivery === 2) {
        ctrl.model.axProduct = {id: productCreativeTag};
        return;
      }
      if (ctrl.selectedCreativeType === 2) {
        ctrl.model.axProduct = {id: officialHighImpactSimple};
        return;
      }
      ctrl.model.axProduct = {id: officialProductSimple};
    }

    const workOutDefaultCurrency = defaultCurrencyDeriver(ctrl, localUserProfile);

    function marketplaceProductQuery(resetQuery) {
      if (model.axProduct || _.get(ctrl.model, ['team', 'type']) === 'AX_ADVERTISER') {
        let teamToProduct;
        let defaultsQuery;
        ctrl.showIabCategory = model.type !== 'CREATIVE_TAG';
        if (ctrl.isNew && !model.axProduct) {
          if (!defaultsQuery) {
            defaultsQuery = NetworkData.get({filterByValue: 'defaultMarketplaceProducts', filterBy: 'type'}).$promise.then(function(page) {
              if (_.get(page, ['results', 'length'])) {
                const entry = page.results[0];
                try {
                  teamToProduct = JSON.parse(entry.data.teamToProduct);
                } catch {
                  $log.warn("Found a problem find teams to products for defaults");
                }
              }
            });
          }
        }

        if (mpQuery && !resetQuery) {
          getUiFees();
          return;
        }
        ctrl.marketplaceResolved = false;
        mpQuery = MarketplaceProduct.query({excludeInvalid: true, team: _.get(ctrl, ['model', 'team', 'id'], "")}).$promise.then(function(page) {
          ctrl.marketplaceProducts = page.results;

          const hasCreativeTagProduct = _.find(ctrl.marketplaceProducts, function(mp) {
            return mp.id === productCreativeTag;
          });
          if (!hasCreativeTagProduct) {
            ctrl.productSimples = _.filter(ctrl.productSimples, function(p) {
              return p.id !== 2;
            });
          }

          $q.when(defaultsQuery).then(function() {
            const currentTeamId = _.get(ctrl.model, ['team', 'id']);
            if (teamToProduct) {
              const allMatchedMps = _.filter(teamToProduct, function(val, key) {
                const isTeam = LocalUserPermissions.has('AX_ADVERTISER', key) || LocalUserPermissions.has('MANAGE_ADVERTISING', key);
                return (currentTeamId === key || !currentTeamId) && isTeam && _.find(ctrl.marketplaceProducts, function(mp) {
                  return mp.id === teamToProduct[key];
                });
              });
              const matchedMpId = allMatchedMps.length === 1 ? allMatchedMps[0] : null;
              if (matchedMpId) {
                const matchedMp = _.find(ctrl.marketplaceProducts, ['id', matchedMpId]);
                if (matchedMp) {
                  ctrl.model.axProduct = matchedMp;
                  _.set(ctrl.model, 'targeting', angular.copy(matchedMp.targeting));
                  ctrl.model.rateLimits = matchedMp.rateLimits;
                  ctrl.rateLimitReadOnly = ctrl.model.rateLimits.length > 0;
                }
              }
            }

            if (ctrl.model.axProduct) {
              const foundProduct = _.find(ctrl.marketplaceProducts, function(mp) {
                return ctrl.model.axProduct.id === mp.id;
              });
              if (foundProduct) {
                ctrl.showIabCategory = ctrl.showIabCategory && foundProduct.type !== 'CREATIVE_TAG';
                ctrl.model.axProduct = foundProduct;
                getUiFees();
                workOutMinMaxCpm(foundProduct);
                workOutDefaultCurrency(null, null, ctrl.model.axProduct);
                ctrl.model.bidSpecification = ctrl.model.bidSpecification || {};
                ctrl.showVCpmStrategy = _.includes(['ALLOW_VCPM', 'ALLOW_ALL'], foundProduct.bidStrategyOption);
                ctrl.showDynamicCpmStrategy = _.includes(['ALLOW_DYNAMIC', 'ALLOW_ALL'], foundProduct.bidStrategyOption);
                ctrl.allowedDeliveryCurves = _.get(foundProduct, 'smoothingChoices') || [];
                if (ctrl.allowedDeliveryCurves.length === 0) {
                  ctrl.allowedDeliveryCurves = null;
                }
                if (foundProduct.cpmSpecification === 'FIXED') {
                  ctrl.model.bidSpecification.cpm = ctrl.model.minimumCpm;
                  ctrl.minCpmForSave = true;
                }

                if (ctrl.isNew) {
                  if (foundProduct.cpmSpecification === 'FIXED') {
                    ctrl.model.bidSpecification.cpm = ctrl.model.minimumCpm;
                  }
                  if (ctrl.showDynamicCpmStrategy && _.get(ctrl, ['model', 'type']) !== 'CREATIVE_TAG') {
                    ctrl.model.bidSpecification.bidStrategy = 'DYNAMIC';
                  }
                  const defaultTargeting = angular.copy(_.get(ctrl.model, ['team', 'defaultTargeting']));
                  if (defaultTargeting) {
                    const dts = TargetingHelper.getTargetsWithTargetsDirectly(defaultTargeting);
                    if (!_.isEmpty(dts)) {
                      _.forEach(dts, function(dt) {
                        ctrl.model.targeting[dt.targets] = angular.copy(defaultTargeting[dt.targets]);
                      });
                    }
                    renameProduct(ctrl.model.team);
                  }
                }
              }
            }
            updateProductSimpleSelection();
          });
        }).finally(function() {
          ctrl.marketplaceResolved = true;
          updateAvailableSmoothingOptions();
          renameProduct(ctrl.model.team);
        });
      }
    }

    ctrl.iabTaxonomy = angular.copy(IAB_TAXONOMY);
    ctrl.orderSelect = {
      onSelect: function() {
        ctrl.marketplaceResolved = false;
        Order.get({id: ctrl.model.order.id}, function(order) {
          const teamId = _.get(order, ['team', 'id']);
          if (ctrl.model.team === teamId) {
            ctrl.marketplaceResolved = true;
          }
          if (teamId && ctrl.model.team !== teamId) {
            Team.get({id: teamId}, function(team) {
              ctrl.model.team = team;
              marketplaceProductQuery(true);
              getUiFees();
              workOutMinMaxCpm(ctrl.model.axProduct);
              workOutDefaultCurrency(null, team, null);
              renameProduct(ctrl.model.team);
            });
          } else {
            ctrl.model.team = null;
            marketplaceProductQuery(true);
          }
        });
      }
    };

    ctrl.bulkAction = creativeBulkAction(ctrl, {$uibModal: $uibModal});

    ctrl.copyInBulk = function() {
      creativeCopyInBulk(ctrl, {
        $uibModal: $uibModal,
        Creative: Creative,
        $q: $q
      }, function() {
        ctrl.makeQuery();
      });
    };

    const calcCostFunc = function() {
      const liCostModel = _.get(ctrl.model, ['bidSpecification', 'cpmCostModel']);
      const costModel = (liCostModel ? liCostModel : LocalNetworkProfile.getDefaultCpmCostModel()) || 'PER_IMPRESSION';
      const impRefField = costModel === 'PER_IMPRESSION' ? 'IMPRESSION' : 'RENDERED_IMPRESSION';
      const dataField = costModel === 'PER_IMPRESSION' ? 'impressions' : 'renderedImpressions';

      let cpmCost = 0;
      if (_.get(ctrl.model.objectives, impRefField) && _.get(ctrl.model.bidSpecification.cpm, "amount")) {
        cpmCost = angular.copy(ctrl.model.bidSpecification.cpm);
        cpmCost.amount = cpmCost.amount * Math.max(0, ((ctrl.model.objectives[impRefField] - ctrl.data[dataField]) / 1000));
      }
      let cpcCost = 0;
      if (_.get(ctrl.model.objectives, 'CLICK') && _.get(ctrl.model.bidSpecification.cpc, "amount")) {
        cpcCost = angular.copy(ctrl.model.bidSpecification.cpc);
        cpcCost.amount = cpcCost.amount * Math.max(0, (ctrl.model.objectives.CLICK - ctrl.data.clicks));
      }
      if (_.get(ctrl.model.objectives, 'IMPRESSION') || _.get(ctrl.model.objectives, 'VIEWABLE_IMPRESSION') || _.get(ctrl.model.objectives, 'VISIBLE_IMPRESSION') || _.get(ctrl.model.objectives, 'CLICK') || _.get(ctrl.model.objectives, 'RENDERED_IMPRESSION')) {
        if (cpmCost === 0 && _.get(ctrl.model.bidSpecification.cpm, "amount")) {
          cpmCost = "Unlimited";
        }
      }
      ctrl.costFailsafe = false;
      if (ctrl.mistakenBidEnabled && ctrl.getForm()) {
        const cpm = _.get(ctrl.model, ['bidSpecification', 'cpm'], {amount: 0, currency: 'AUD'});
        const cpc = _.get(ctrl.model, ['bidSpecification', 'cpc'], {amount: 0, currency: 'AUD'});
        const cpa = _.get(ctrl.model, ['bidSpecification', 'cpa', 'cost'], {amount: 0, currency: 'AUD'});
        const cpmBidTooHigh = isBidTooHigh(cpm, originalCpm);
        let cpcBidTooHigh = isBidTooHigh(cpc, originalCpc);
        let cpaBidTooHigh = isBidTooHigh(cpa, originalCpa);
        if (cpmBidTooHigh || cpcBidTooHigh || cpaBidTooHigh) {
          ctrl.costFailsafe = cpmBidTooHigh || cpcBidTooHigh || cpaBidTooHigh;
          ctrl.getForm().$pending = true;
        }
      }
      ctrl.maxCpmCost = cpmCost;
      ctrl.maxCpcCost = cpcCost;
      getUiFees();
    };

    ctrl.confirmBid = function() {
      ctrl.mistakenBidEnabled = false;
      ctrl.costFailsafe = false;
      ctrl.getForm().$pending = false;
    };

    function doDimChecks() {
      if (ctrl.isLiTemplate) {
        return;
      }

      ctrl.checksDone = false;
      ctrl.unmatchedCreatives = [];
      ctrl.availableFixed = [];
      ctrl.availableRanges = [];
      if (ctrl.model.type !== 'CREATIVE_TAG' && !ctrl.isNew) {
        const targetingCount = TargetingHelper.getSizeOfTargetingTypes(ctrl.model.targeting, ADN_TARGETING_TYPES.publisher.targets) +
          TargetingHelper.getSizeOfTargetingTypes(ctrl.model.targeting, ADN_TARGETING_TYPES.site.targets) +
          TargetingHelper.getSizeOfTargetingTypes(ctrl.model.targeting, ADN_TARGETING_TYPES.adUnit.targets);

        if (targetingCount > 0) {
          LineItem.dimCheck(ctrl.model.id).then(function(data) {
            ctrl.unmatchedCreatives = _.keyBy(data.unmatchedCreatives, 'id');
            ctrl.checksDone = true;

            _.forEach(data.checks, function(check) {
              ctrl.availableFixed = ctrl.availableFixed.concat(check.unmatchedFixed).concat(check.matchedFixed);
              ctrl.availableRanges = ctrl.availableRanges.concat(check.unmatchedRanges).concat(check.matchedRanges);
            });

            ctrl.availableFixed = _.uniqWith(ctrl.availableFixed, _.isEqual);
            ctrl.availableRanges = _.uniqWith(ctrl.availableRanges, _.isEqual);
          });
        }
      }
    }

    const formatFeesCache = [];
    let numCreativeCache = -1;

    ctrl.canLimitBurnRates = localUserProfile.canLimitBurnRates();
    model.burnRateLimits = model.burnRateLimits || [];
    if (model.burnRateLimits.length === 1) {
      ctrl.burnRateLimitsType = 'SINGLE';
    } else if (model.burnRateLimits.length === 4 && _.filter(model.burnRateLimits, function(bl) {return bl > 0;}).length > 0) {
      ctrl.burnRateLimitsType = 'VARIABLE';
    } else {
      model.burnRateLimits = [0.0, 0.0, 0.0, 0.0];
      ctrl.burnRateLimitsType = 'NONE';
    }

    function getUiFees(creativeFormatFees, numCreatives) {
      const grossCpm = _.cloneDeep(_.get(ctrl, ['model', 'bidSpecification', 'cpm']) || _.get(model, ['bidSpecification', 'cpm']));
      const grossCpc = _.cloneDeep(_.get(ctrl, ['model', 'bidSpecification', 'cpc']) || _.get(model, ['bidSpecification', 'cpc']));
      const referenceCurrency = _.get(grossCpm, ['currency']) || _.get(grossCpc, ['currency']) || _.get(ctrl, ['model', 'billingCurrency']) || 'NA';
      ctrl.uiFees = ctrl.uiFees || {fees: []};
      ctrl.uiFees.fees.length = 0;

      _.forEach(_.cloneDeep(_.get(ctrl, ['model', 'bidSpecification', 'cpmFees'])), function(fee) {
        ctrl.uiFees.fees.push(fee);
      });
      _.forEach(_.cloneDeep(_.get(ctrl, ['model', 'bidSpecification', 'audienceFees'])), function(fee) {
        if (fee.name === 'NORDIC_DATA_RESOURCES') {
          fee.name = "Audience Fee for Nordic Data Resources";
        } else {
          const targetType = getTargetingType(fee.name);
          if (targetType) {
            fee.name = "Fee for " + targetType.title + " targeting";
          } else {
            fee.name = "Audience Fee for Third-Party Audience";
          }
        }
        fee.id = 'audience-fee'; // needed to make the name read-only
        ctrl.uiFees.fees.push(fee);
      });
      _.forEach(_.cloneDeep(_.get(ctrl, ['model', 'bidSpecification', 'supplementaryFees'])), function(fee) {
        fee.id = 'supplementary-fee'; // needed to make the name read-only
        ctrl.uiFees.fees.push(fee);
      });
      if (_.get(ctrl.model, ['bidSpecification', 'cpmTechFee', 'type'])) {
        const cpmTechFee = _.cloneDeep(ctrl.model.bidSpecification.cpmTechFee);
        cpmTechFee.name = LocalNetworkProfile.getNetworkTranslations("techFee");
        cpmTechFee.id = "tech-fee";
        ctrl.uiFees.fees.push(cpmTechFee);
      }
      if (creativeFormatFees) {
        numCreativeCache = numCreatives;
        formatFeesCache.length = 0;
        _.forEach(creativeFormatFees, function(f) {
          const fee = simplifyFee(LocalNetworkProfile, referenceCurrency, f);
          if (fee) {
            formatFeesCache.push(fee);
          }
        });
      }
      if (formatFeesCache.length) {
        const uniqFees = _.uniqBy(formatFeesCache, function(ff) {
          if (ff.monetary.currency === referenceCurrency) {
            return ff.monetary.amount;
          }
        });
        if (uniqFees.length) {
          const ff = {
            name: LocalNetworkProfile.getNetworkTranslations("creativeFormatFee"),
            id: "creative-format-fee",
            type: 'MONETARY',
            monetary: {
              currency: uniqFees[0].monetary.currency
            }
          };
          if (uniqFees.length === 1 && numCreativeCache === formatFeesCache.length) {
            ff.monetary.amount = uniqFees[0].monetary.amount;
          } else {
            const minFee = numCreativeCache > formatFeesCache.length ? {monetary: {amount: 0}} : _.sortBy(uniqFees, ['monetary.amount'])[0];
            const maxFee = _.sortBy(uniqFees, ['monetary.amount'])[uniqFees.length - 1];
            ff.monetary.amount = minFee.monetary.amount;
            ff.monetary.maxAmount = maxFee.monetary.amount;
          }
          ctrl.uiFees.fees.push(ff);
        }
      }

      function standardiseFees(fees) {
        _.forEach(fees, function(theFee) {
          if (theFee.type === 'MULTI_CURRENCY_MONETARY') {
            const matchingFee = getMatchingFee(theFee.multiCurrencyMonetaryAmounts, referenceCurrency);
            if (matchingFee) {
              theFee.monetary = matchingFee;
            }
          }
        });
      }

      standardiseFees(ctrl.uiFees.fees);
      standardiseFees(creativeFormatFees);
      ctrl.uiFees.grossCpm = MoneyHelper.fixMoney(grossCpm);
      ctrl.uiFees.netCpm = calculateNetCpm(ctrl.uiFees.fees, MoneyHelper.fixMoney(grossCpm));
      return referenceCurrency;
    }

    ctrl.selectTeams = function() {
      ctrl.editsMade();
      ctrl.model.teams = _.map(ctrl.teams, 'id');
    };

    ctrl.deselectTeams = function() {
      ctrl.editsMade();
      ctrl.model.teams = [];
    };

    Team.query(function(page) {
      const allTeams = page.results;
      const isViewAllObjects = LocalUserPermissions.hasNetwork('VIEW_ALL_OBJECTS');
      ctrl.teams = isViewAllObjects ? allTeams : _.filter(allTeams, function(g) {
        return LocalUserPermissions.isPartOf(g.network.tagId, g.id);
      });
    });

    ctrl.ignoreProductChange = function() {
      ctrl.showEverything = !LocalNetworkProfile.isPureMarketplacePlatform() || ctrl.model.ignoreProduct;
    };

    const afterInitAfterSave = function(data) {
      ctrl.model.ignoreProduct = !Object.prototype.hasOwnProperty.call(ctrl.model, 'ignoreProduct') ? LocalNetworkProfile.getDefaults()['defaultIgnoreMarketplaceTargeting'] : ctrl.model.ignoreProduct;
      ctrl.model.biddingAlgorithm = ctrl.model.biddingAlgorithm || 'DEFAULT';
      if (_.get(ctrl.model, ['bidSpecification', 'optimiseFor', 'customEventType', 'id'])) {
        ctrl.model.bidSpecification.optimiseFor.eventType = 'CUSTOM';
      }

      filterOutUnwantedTargets(ctrl, ADN_TARGETING_TYPES.site);
      filterOutUnwantedTargets(ctrl, ADN_TARGETING_TYPES.adUnit);
      filterOutUnwantedTargets(ctrl, ADN_TARGETING_TYPES.publisher);

      function ifSelProductFound(doFunc) {
        $q.when(mpQuery).then(function() {
          if (!ctrl.model.axProduct) {
            return;
          }

          const selProduct = _.find(ctrl.marketplaceProducts, function(mp) {
            return mp.id === ctrl.model.axProduct.id;
          });
          if (selProduct) {
            doFunc(selProduct);
            workOutMinMaxCpm(selProduct);
            ctrl.allowedDeliveryCurves = _.get(ctrl.model.axProduct, 'smoothingChoices') || [];
            if (ctrl.allowedDeliveryCurves.length === 0) {
              ctrl.allowedDeliveryCurves = null;
            }
            const curves = ctrl.allowedDeliveryCurves === null ? ['EVEN', 'FRONT_LOADED', 'CAPPED'] : ctrl.allowedDeliveryCurves;
            if (!ctrl.model.deliveryCurve) {
              ctrl.model.deliveryCurve = curves[0];
            }
            updateAvailableSmoothingOptions();
          }
        });
      }

      function setShowBidStrategy(selProduct) {
        if (!selProduct) {
          return;
        }
        ctrl.showBidStrategy = _.get(ctrl.model, ['bidSpecification', 'bidStrategy']) || _.includes(['ALLOW_VCPM', 'ALLOW_DYNAMIC', 'ALLOW_ALL'], selProduct.bidStrategyOption);
        ctrl.showVCpmStrategy = _.includes(['ALLOW_VCPM', 'ALLOW_ALL'], selProduct.bidStrategyOption);
        ctrl.showDynamicCpmStrategy = _.includes(['ALLOW_DYNAMIC', 'ALLOW_ALL'], selProduct.bidStrategyOption);
        if (ctrl.isNew) {
          if (ctrl.showDynamicCpmStrategy) {
            ctrl.model.bidSpecification.bidStrategy = 'DYNAMIC';
          } else {
            ctrl.model.bidSpecification.bidStrategy = null;
          }
        }
      }

      getUiFees();
      doDimChecks();
      ctrl.model.teams = _.map(ctrl.model.teams, function(team) {
        return _.get(team, ['id'], team);
      });

      if (ctrl.isLiTemplate) {
        ctrl.model.teamType = _.get(ctrl.model, 'order') || _.get(ctrl.model, 'teams', []).length === 0 ? 'ORDER' : 'TEAMS';
      }

      _.set(ctrl.model, 'firstPartyAudiences', _.map(_.get(data || ctrl.model, 'firstPartyAudiences') || [], 'id'));
      ctrl.showEverything = ctrl.showEverything === true || !LocalNetworkProfile.isPureMarketplacePlatform() || _.get(ctrl.model, ['team', 'type']) === 'STANDARD';
      ctrl.showProposed = ctrl.showProposed || ctrl.showEverything || ((ctrl.model.userState === 'PROPOSED' || ctrl.model.userState === 'APPROVED') && (ctrl.model.executionState === 'NOT_READY' || ctrl.model.executionState === 'READY'));
      ctrl.hasShowEverythingOption = LocalNetworkProfile.isPureMarketplacePlatform() && ctrl.isAdOps;
      ctrl.isLockedDown = ctrl.isAxPublisher;
      updateAvailableSmoothingOptions();

      const skipValidationWarning = 'validation.warning.lineItem.unapproved';
      if (!ctrl.isAxAdvertiser) {
        ifSelProductFound(function(selProduct) {
          commons.setObjectiveFields(selProduct);
          // doing this to get the expanded info
          ctrl.model.axProduct = selProduct;
          setShowBidStrategy(selProduct);
        });

        return;
      }

      ifSelProductFound(function(selProduct) {
        // doing this to get the expanded info
        ctrl.model.axProduct = selProduct;
        commons.setObjectiveFields(selProduct);
        setShowBidStrategy(selProduct);
        ctrl.requiresReview = selProduct.lineItemWorkflowType === 'PROPOSED';
        if (ctrl.requiresReview && !model.axProduct) {
          ctrl.model.userState = 'PROPOSED';
        }
        ctrl.showIabCategory = ctrl.showIabCategory && selProduct.type !== 'CREATIVE_TAG';
        ctrl.isLockedDown = ctrl.model.userState !== 'PROPOSED' && selProduct.lineItemWorkflowType === 'PROPOSED';
        ctrl.submitForReview = ctrl.model.userState === 'PROPOSED' && selProduct.lineItemWorkflowType === 'PROPOSED';
        ctrl.canSubmitForReview = _.get(ctrl.model, ['validationWarnings', 'length']) === 0 || (_.get(ctrl.model, ['validationWarnings', 'length']) === 1 && _.get(ctrl.model.validationWarnings, [0, 'code']) === skipValidationWarning);
        ctrl.skips = selProduct.lineItemWorkflowType === 'PROPOSED' && (ctrl.model.userState === 'PROPOSED' || ctrl.model.userState === 'SUBMITTED' || ctrl.model.userState === 'RESERVED') ? [skipValidationWarning] : [];
        ctrl.canStop = ctrl.model.userState === 'APPROVED' && selProduct.lineItemWorkflowType === 'PROPOSED';
        ctrl.rateLimitReadOnly = _.get(ctrl.model.axProduct, ['rateLimits', 'length']) > 0;
        if (selProduct.type === "CREATIVE_TAG") {
          ctrl.model.type = 'CREATIVE_TAG';
          ctrl.showObjectives = (ctrl.showObjectives && selProduct.objectiveSpecification !== 'OPTIONAL') || ctrl.model.objectives;
        } else {
          ctrl.model.type = 'AUCTION';
          defaultShowObjective();
        }
        if (ctrl.isNew) {
          ctrl.model.bidSpecification.bidStrategy = selProduct.bidStrategyOption === 'ALLOW_VCPM' ? 'VIEWABLE' : null;
        }
        setShowBidStrategy(selProduct);
        const currentTargets = TargetingHelper.getTargetsWithTargets(ctrl.model, ['targeting']);
        const optionalTargeting = _.get(ctrl.model, ['axProduct', 'optionalTargetTypes'], []);
        ctrl.hideTargeting = ctrl.isAxAdvertiser && (!ctrl.model.axProduct || (_.isEmpty(currentTargets) && optionalTargeting.length === 0));
      });
    };

    ctrl.data = {clicks: 0, impressions: 0, renderedImpressions: 0, cost: 0, viewableImpressions: 0, visibleImpressions: 0, hasStats: false};
    setLineItemDefaults(LocalNetworkProfile, ctrl, model);
    if (!ctrl.isNew && !ctrl.isLiTemplate && !axPublisherPermission) {
      statsResource.get({
        lineItemId: model.id,
        groupBy: null
      }, function(apiData) {
        ctrl.data.clicks = apiData.clicks;
        ctrl.data.impressions = apiData.impressions;
        ctrl.data.cost = apiData.cost;
        ctrl.data.viewableImpressions = apiData.viewables;
        ctrl.data.visibleImpressions = apiData.visibles;
        ctrl.data.renderedImpressions = apiData.rendered;
        ctrl.data.hasStats = true;
        calcCostFunc();
      });

      if (model.smoothed && model.executionState !== 'NOT_READY') {
        burnRatesResource.get({id: model.id}, function(response) {
          ctrl.currentBurnRate = response.currentBurnRate;
        });
      }
    }

    const creativeQuery = function() {
      if (_.isFunction(ctrl.makeQuery)) {
        return;
      }
      // want to minimise layout parameter information, which can be heavy duty
      adnListHelper.setUpBasicList(ctrl, Creative, 'fullCreativesForLineItem', {
        moreParams: {lineItem: model.id},
        concise: true,
        pageSize: 101,
        afterFill: function() {
          ctrl.updateRelData(ctrl.items, 'creatives');

          const data = postFillGenerateFormatFees(ctrl);
          const refCurrency = getUiFees(data.creativeFormatFees, data.validItems.length);
          _.forEach(ctrl.items, function(item) {
            const fee = simplifyFee(LocalNetworkProfile, refCurrency, item.creativeFormatFee);
            if (fee) {
              item.simplifiedCreativeFormatFee = fee;
            }
          });
        }
      });
    };

    function modLineItemDates() {
      if (ctrl.model.startDate) {
        ctrl.model.modStartDate = adnTimeShifter.timeShift(ctrl.model.startDate);
      }
      if (ctrl.model.endDate) {
        ctrl.model.modEndDate = adnTimeShifter.timeShift(ctrl.model.endDate);
      }
    }

    adnFormHelper.setUpForm(ctrl, model, {
      modelComms: modelComms,
      afterInitAfterSave: function(obj) {
        updateBudgets(ctrl, obj);
      },
      afterSave: function(persistedLineItem) {
        ctrl.targetingResult = ctrl.targetingResult || {};
        ctrl.targetingResult.data = false;
        ctrl.model.executionStateEndedReason = null;
        _.assign(ctrl.model, _.pick(persistedLineItem, [
          'executionState',
          'executionStateEndedReason',
          'validationWarnings',
          'objectiveDeliveryPredictions',
          'axProduct',
          'userState',
          'type',
          'targeting',
          'rateLimits',
          'submissionMessage'
        ]));
        _.set(ctrl.model, ['bidSpecification', 'cpmFees'], _.get(persistedLineItem, ['bidSpecification', 'cpmFees']));
        _.set(ctrl.model, ['bidSpecification', 'cpmTechFee'], _.get(persistedLineItem, ['bidSpecification', 'cpmTechFee']));
        _.set(ctrl.model, ['bidSpecification', 'supplementaryFees'], _.get(persistedLineItem, ['bidSpecification', 'supplementaryFees']));
        _.set(ctrl.model, ['bidSpecification', 'audienceFees'], _.get(persistedLineItem, ['bidSpecification', 'audienceFees']));
        ctrl.minCpmForSave = false;
        ctrl.model.order = persistedLineItem.order;

        if (!ctrl.isLiTemplate) {
          creativeQuery();

          dataViewResource.get('LineItemView', {id: persistedLineItem.id}, false).then(function(liv) {
            lineItemView = liv;
            ctrl.model.advertiser = _.get(lineItemView, 'advertiser');
            workOutDefaultCurrency(ctrl.model.advertiser, null, null);
            ctrl.updateModelHappened();
          });
        }

        afterInitAfterSave(persistedLineItem);

        ctrl.currentEndDate = ctrl.model.endDate;
        ctrl.currentSmoothed = ctrl.model.smoothed;

        if (full && viewType === 2) {
          $state.go('app.line-items.line-item', {id: persistedLineItem.id, viewType: 2, full: false}, {inherit: false});
        }
        modelComms.commMade({full: false});
        adnInactiveTargets.cancelExistingData(model.id);
      },
      afterInit: function() {
        if (!ctrl.isNew && !ctrl.isLiTemplate) {
          creativeQuery();
        }
        modLineItemDates();

        // for an AX_PUBLISHER viewing a line item, some parts of the line item will be suppressed
        ctrl.readOnlyAxPublisher = axPublisherPermission && !ctrl.model.order;
        ctrl.showTargeting = !axPublisherPermission || ctrl.model.targeting;
        ctrl.showOrder = !axPublisherPermission || _.get(ctrl.model, ['order', 'name']);
        defaultShowObjective();
        ctrl.showBidSpecification = !axPublisherPermission || ctrl.model.bidSpecification;
        ctrl.showExclusionLabels = !axPublisherPermission || ctrl.model.exclusionLabels;
        ctrl.showLabels = !axPublisherPermission || ctrl.model.labels;
        ctrl.blockSubmit = readOnly || ctrl.blockSubmit || ctrl.readOnlyAxPublisher;

        if (ctrl.isSelfService && !ctrl.model.order) {
          Order.query({minimal: true}, function(page) {
            ctrl.model.order = _.get(_.head(page.results), 'id') || {};
          });
        }
        ctrl.model.advertiser = _.get(lineItemView, 'advertiser') || ctrl.model.advertiser;
        ctrl.model.team = _.get(lineItemView, 'team');
        marketplaceProductQuery();
        afterInitAfterSave();
        calcCostFunc();
        renameProduct(ctrl.model.team);
        ctrl.model.bidSpecification = ctrl.model.bidSpecification || {};
        ctrl.model.bidSpecification.cpa = ctrl.model.bidSpecification.cpa || {};
        ctrl.model.bidSpecification.cpa.cost = ctrl.model.bidSpecification.cpa.cost || {};
        ctrl.model.bidSpecification.cpa.initialECpm = ctrl.model.bidSpecification.cpa.initialECpm || {};
        ctrl.model.bidSpecification.cpmCostModel = ctrl.model.bidSpecification.cpmCostModel || null;
        ctrl.model.bidSpecification.bidStrategy = ctrl.model.bidSpecification.bidStrategy || null;
        if (!ctrl.model.axProduct) {
          ctrl.showVCpmStrategy = true;
          ctrl.showDynamicCpmStrategy = true;
        }
        ctrl.axUserStates = {};
        if (ctrl.isAxAdvertiser) {
          ctrl.axUserStates = {
            PROPOSED: ctrl.model.userState === 'PROPOSED',
            SUBMITTED: ctrl.model.userState === 'SUBMITTED',
            RESERVED: ctrl.model.userState === 'RESERVED'
          };
        }

        if (ctrl.isNew) {
          ctrl.model.deliveryCurve = ctrl.isAxAdvertiser ? LocalNetworkProfile.getDefaults().marketplaceDeliveryCurve : LocalNetworkProfile.getDefaults().deliveryCurve;
        }

        ctrl.currentEndDate = ctrl.model.endDate;
        ctrl.currentSmoothed = ctrl.model.smoothed;
      }
    });
    ctrl.model.targeting = ctrl.model.targeting || {};

    ctrl.model.optimiseCreativesFor = ctrl.model.optimiseCreativesFor || null;
    ctrl.model.bidSpecification.optimiseFor = ctrl.model.bidSpecification.optimiseFor || {eventType: null};

    commons.setObjectiveFields();
    commons.setBidSpecificationFields();

    ctrl.modelObjectiveDeliveryCss = {
      default: 'label-default',
      UNAVAILABLE: 'label-warning',
      DELIVER: 'label-success',
      UNDER_DELIVER: 'label-danger',
      UNCERTAIN: 'label-warning'
    };
    ctrl.calcCost = calcCostFunc;

    ctrl.showCpa = _.get(ctrl.model.bidSpecification, 'cpa.cost.amount') >= 0;
    ctrl.setCpaLink = function() {
      ctrl.cpaLinkLineItem = CpaDefaults.getLink(_.get(ctrl.cpaCustomEvent, ['id']), LocalNetworkProfile.get('tagId'), _.get(ctrl.model, 'id'));
      ctrl.cpaLinkAdvertiser = CpaDefaults.getLink(_.get(ctrl.cpaCustomEvent, ['id']), LocalNetworkProfile.get('tagId'), _.get(ctrl.model, ['advertiser', 'id']));
      ctrl.cpaScriptLineItem = CpaDefaults.getScript(_.get(ctrl.cpaCustomEvent, ['id']), LocalNetworkProfile.get('tagId'), _.get(ctrl.model, 'id'));
      ctrl.cpaScriptAdvertiser = CpaDefaults.getScript(_.get(ctrl.cpaCustomEvent, ['id']), LocalNetworkProfile.get('tagId'), _.get(ctrl.model, ['advertiser', 'id']));
    };
    ctrl.setCpaLink();

    CustomEvent.query().$promise.then(function(page) {
      ctrl.customEvents = page.results;
    });

    commons.setUpTiers();

    commons.setObjectivesAsString();

    ctrl.openCreativePreview = function(creative) {
      ctrl.editsMade();
      $uibModal.open({
        size: 'lg',
        template: creativePreviewTemplate,
        resolve: {
          creative: function() {
            return creative;
          },
          teamId: function() {
            return _.get(lineItemView, 'order.team.id');
          },
          lineItem: function() {
            return ctrl.model;
          }
        },
        controllerAs: 'preview',
        controller: 'CreativePreviewCtrl'
      });
    };

    ctrl.editBudgets = function() {
      ctrl.budgetResult = {
        data: false
      };
      ctrl.budgetResult.modalInstance = $uibModal.open({
        template: orderBudgetsTemplate,
        controller: 'BudgetModalCtrl',
        controllerAs: 'modalCtrl',
        size: 'lg',
        windowClass: '',
        resolve: {
          modelObject: function() {
            return _.cloneDeep(ctrl.model);
          },
          budgetResult: function() {
            return ctrl.budgetResult;
          },
          moneyCurrency: function() {
            return _.cloneDeep(ctrl.moneyCurrency);
          },
          objTranslation: function() {
            return "Line Item";
          }
        }
      });
      ctrl.budgetResult.modalInstance.result.then(function(data) {
        if (data.obj) {
          _.set(ctrl.model, ['budget'], _.get(data, ['obj', 'budget']));
          _.set(ctrl.model, ['budgetRollover'], _.get(data, ['obj', 'budgetRollover']));
          _.set(ctrl.model, 'validationWarnings', data.obj.validationWarnings);
          _.set(ctrl.model, 'startDate', data.obj.startDate);
          _.set(ctrl.model, 'endDate', data.obj.endDate);
          _.set(ctrl.model, 'objectives', data.obj.objectives);
          modLineItemDates();
          updateBudgets(ctrl);
        }
      });
    };

    ctrl.editTarget = function(targeting) {
      ctrl.targetingResult = {
        data: false
      };
      $uibModal.open({
        template: targetingTemplate,
        controller: 'TargetingCtrl',
        size: 'xl',
        resolve: {
          modelLineItem: function() {
            const lineItemCopy = angular.copy(ctrl.model);
            lineItemCopy.creativesForReach = ctrl.items;
            return lineItemCopy;
          },
          pageType: function() {
            return "lineItem";
          },
          reachEnabled: function() {
            return 'LINE_ITEM';
          },
          mandatoryTargets: function() {
            return TargetingHelper.getTargetsWithTargets(ctrl.model, ['axProduct', 'targeting']);
          },
          overridableTargeting: function() {
          },
          availableTargets: function() {
            if (!ctrl.isAxAdvertiser && (ctrl.model.ignoreProduct || !_.get(ctrl.model, ['axProduct']))) {
              return;
            }
            const currentTargets = TargetingHelper.getTargetsWithTargets(ctrl.model, ['targeting']);
            const mandatoryTargetIds = TargetingHelper.getTargetsWithTargets(ctrl.model, ['axProduct', 'targeting']);
            const optionalTargeting = _.get(ctrl.model, ['axProduct', 'optionalTargetTypes'], []);
            const optionalTargetIds = _.map(_.filter(ADN_TARGETING_TYPES, function(tt) {
              return _.indexOf(optionalTargeting, tt.apiType) > -1 || _.indexOf(mandatoryTargetIds, tt.id) > -1 || currentTargets[tt.id];
            }), 'id');
            return _.pick(ADN_TARGETING_TYPES, optionalTargetIds);
          },
          targetingMode: function() {
            return null;
          },
          targeting: function() {
            return targeting;
          },
          isLockedDown: function() {
            return ctrl.isLockedDown;
          }
        }
      })
        .result.then(function(wc) {
        _.assign(targeting, wc.wc);
        if ((_.get(targeting, 'thirdPartyAudienceTargets') || []).length > 0) {
          // NDR is currently the only possible third-party provider. When we have others we will
          // need to check the specific audiences targeted to determine what providers must be enabled
          ctrl.model.enabledThirdPartyProviders = ['NORDIC_DATA_RESOURCES'];
        } else {
          ctrl.model.enabledThirdPartyProviders = [];
        }
        adnInactiveTargets.cancelExistingData(model.id);
        if (!ctrl.isNew) {
          ctrl.model.savePartial(['targeting', 'enabledThirdPartyProviders'], null, {ignoreProduct: ctrl.model.ignoreProduct}).then(function(respLineItem) {
            ctrl.targetingResult.data = true;
            _.set(ctrl.model, 'validationWarnings', respLineItem.validationWarnings);
            _.set(ctrl.model, 'targeting', respLineItem.targeting);
            _.set(ctrl.model, 'enabledThirdPartyProviders', respLineItem.enabledThirdPartyProviders);
            _.set(ctrl.model, 'bidSpecification.audienceFees', _.get(respLineItem, ['bidSpecification', 'audienceFees']));
            getUiFees();
            doDimChecks();
            workOutMinMaxCpm(ctrl.model.axProduct);

            SubmitCalls.updateFormWarnings(ctrl.getForm(), respLineItem);
          }, function(err) {
            ctrl.targetingResult.errors = _.get(err, ['data', 'errors']);
            Rollbar.error("Line item targeting problem", err);
          });
        }
      });
    };

    const lineItemTargetingUpdateCall = $rootScope.$on('lineItemTargetingUpdate', function(event, respLineItem) {
      _.set(ctrl.model, 'validationWarnings', respLineItem.validationWarnings);
      _.set(ctrl.model, 'targeting', respLineItem.targeting);
      _.set(ctrl.model, 'enabledThirdPartyProviders', respLineItem.enabledThirdPartyProviders);
      _.set(ctrl.model, 'bidSpecification.audienceFees', _.get(respLineItem, ['bidSpecification', 'audienceFees']));
      getUiFees();
      workOutMinMaxCpm(ctrl.model.axProduct);

      SubmitCalls.updateFormWarnings(ctrl.getForm(), respLineItem);
    });
    $scope.$on('$destroy', function() {
      lineItemTargetingUpdateCall();
    });

    ctrl.selectAxProduct = function(prod) {
      ctrl.model.axProduct = prod;
      updateProductSimpleSelection();
    };
    ctrl.selectCountry = function(country) {
      ctrl.selectedCountries = ctrl.selectedCountries || [];
      if (ctrl.selectedCountries.indexOf(country.id) > -1) {
        _.pull(ctrl.selectedCountries, country.id);
      } else {
        ctrl.selectedCountries.push(country.id);
      }
      updateProductFromSimpleSelection();
    };
    ctrl.selectCreativeType = function(ct) {
      ctrl.selectedCreativeType = ct.id;
      updateProductFromSimpleSelection();
    };
    ctrl.selectDelivery = function(delivery) {
      ctrl.selectedDelivery = delivery.id;
      updateProductFromSimpleSelection();
    };

    ctrl.selectMp = function() {
      const modalInstance = $uibModal.open({
        template: mpProductTemplate,
        size: 'lg',
        windowClass: '',
        resolve: {
          allProducts: function() {
            return ctrl.marketplaceProducts;
          },
          modelLineItem: function() {
            return angular.copy(ctrl.model);
          }
        },
        controller: function(allProducts, modelLineItem) {
          const modalCtrl = this;
          modalCtrl.allAxProducts = allProducts;
          modalCtrl.index = allProducts.length > 10 ? 0 : 1;
          modalCtrl.loading = false;

          modalCtrl.isMarketplace = LocalNetworkProfile.isPureMarketplacePlatform();

          modalCtrl.axProduct = modelLineItem.axProduct;

          modalCtrl.selectAxProduct = function(prod) {
            modalCtrl.axProduct = prod;
          };

          MarketplaceProductOptions.get(function(data) {
            modalCtrl.optionalTargetingTypes = data.optionalTargetingTypes;
            modalCtrl.layoutCategories = data.layoutCategories;
            modalCtrl.labels = data.labels;
            modalCtrl.adSizes = _.orderBy(data.adSizes, function(ad) {
              return ad === 'ANY' ? 1 : parseInt(ad.split("x")[0], 10);
            });
          });

          modalCtrl.categoryOutputs = function(entry) {
            return _.uniq(_.map(entry, 'detectedCategory'));
          };

          modalCtrl.filter = function() {
            const params = {};
            if (modalCtrl.optionalTargetingType) {
              params.optionalTargeting = modalCtrl.optionalTargetingType;
            }
            if (modalCtrl.layoutCategory) {
              params.layoutCategory = modalCtrl.layoutCategory;
            }
            if (modalCtrl.label) {
              params.label = modalCtrl.label;
            }
            if (modalCtrl.adSize) {
              params.dimensions = modalCtrl.adSize === 'ANY' ? '0x0' : modalCtrl.adSize;
            }
            modalCtrl.loading = true;
            MarketplaceProduct.query(params, function(data) {
              modalCtrl.allAxProducts = data.results;
              modalCtrl.loading = false;
            });
          };

          modalCtrl.saveAxProduct = function() {
            if (!modalCtrl.axProduct) {
              modalInstance.close();
              return;
            }

            modelLineItem.axProduct = modalCtrl.axProduct;

            function rateLimitChecks(rateLimits) {
              ctrl.rateLimitReadOnly = rateLimits.length > 0;
              if (rateLimits.length > 0) {
                ctrl.model.rateLimits = rateLimits;
              }
            }

            if (!ctrl.isNew) {
              modalCtrl.form = {};
              if (modelLineItem.axProduct.lineItemWorkflowType === 'PROPOSED' && modelLineItem.executionState === "NOT_READY") {
                modelLineItem.userState = 'PROPOSED';
              }
              // need to add targeting as a field to save because of API weirdness
              modelLineItem.savePartial(['axProduct', 'targeting', 'userState'], null, {ignoreProduct: ctrl.model.ignoreProduct}).then(function(respLineItem) {
                _.set(ctrl.model, 'axProduct', respLineItem.axProduct);
                _.set(ctrl.model, 'targeting', respLineItem.targeting);
                _.set(ctrl.model, 'validationWarnings', respLineItem.validationWarnings);
                _.set(ctrl.model, ['bidSpecification', 'cpmTechFee'], _.get(respLineItem, ['bidSpecification', 'cpmTechFee']));
                _.set(ctrl.model, ['bidSpecification', 'cpmFees'], _.get(respLineItem, ['bidSpecification', 'cpmFees']));

                getUiFees();
                workOutMinMaxCpm(ctrl.model.axProduct);
                workOutDefaultCurrency(null, null, ctrl.model.axProduct);
                rateLimitChecks(respLineItem.rateLimits);
                modalInstance.close({selected: true});
              }).catch(function(resp) {
                modalCtrl.form.$error = {validation: true};

                modalCtrl.form.apiErrors = _.map(_.get(resp, ['data', 'errors']), function(errData) {
                  return {apiText: $interpolate(errData.error.text)(errData.error.parameters)};
                });
              });
            } else {
              _.set(ctrl.model, 'axProduct', modelLineItem.axProduct);
              _.set(ctrl.model, 'targeting', modelLineItem.axProduct.targeting);
              rateLimitChecks(_.get(ctrl.model, ['axProduct', 'rateLimits'], []));
              modalInstance.close({selected: true});
            }
          };
        },
        controllerAs: 'modalCtrl',
      });
      modalInstance.result.then(function() {
        ctrl.editsMade();
        afterInitAfterSave();
      });
    };

    ctrl.creativeCopy = function() {
      const modalInstance = $uibModal.open({
        template: creativeCopyTemplate,
        controllerAs: 'modalCtrl',
        windowClass: '',
        size: 'lg',
        controller: function() {
          const modalCtrl = this;

          modalCtrl.lineItemName = ctrl.model.name || "This Line Item";
          modalCtrl.newName = "{currentName} (Copy)";

          if (LocalNetworkProfile.isPureMarketplacePlatform() && ctrl.model.advertiser) {
            modalCtrl.specifiedAdvertiser = ctrl.model.advertiser;
          }

          modalCtrl.creativeFilter = function(data) {
            const cft = modalCtrl.creativeFilterText;
            return cft ? _.get(data, 'name', '').toLowerCase().indexOf(cft) > -1 : true;
          };

          modalCtrl.onSelect = function() {
            const lineItemId = _.get(modalCtrl, ['lineItem', 'id']);
            if (!lineItemId) {
              return;
            }
            modalCtrl.loadingCreatives = true;
            Creative.query({lineItem: lineItemId, concise: true, pageSize: 201}, function(page) {
              modalCtrl.creatives = page.results;
              modalCtrl.loadingCreatives = false;
            });
          };
          if (_.get(ctrl, ['items', 'length']) > 0) {
            modalCtrl.lineItem = ctrl.model;
            modalCtrl.onSelect();
          }

          modalCtrl.copyCreative = function(creative) {
            creative.nameNeeded = false;
            if (!modalCtrl.newName) {
              creative.nameNeeded = true;
              return;
            }

            creative.copyDone = false;
            creative.copyFailed = false;
            creative.thinking = true;

            const newId = Uuid.generate();
            creative.name = modalCtrl.newName.replace("{currentName}", creative.name);
            $q.all(Creative.copyCreative(creative, ctrl.model, newId)).then(function() {
              creative.copyDone = true;
              creative.thinking = false;
            }, function() {
              creative.copyError = true;
              creative.thinking = false;
            });
          };
        }
      });
      modalInstance.closed.then(function() {
        ctrl.makeQuery();
      });
    };

    ctrl.updateCpmPrefix = function() {
      ctrl.cpmPrefix = '';
      if (_.get(ctrl.model, ['bidSpecification', 'bidStrategy']) === 'VIEWABLE') {
        let hasMonetaryFees = false;
        if (_.get(ctrl.model, ['bidSpecification', 'cpmFees', 'length']) > 0) {
          hasMonetaryFees = !!_.find(ctrl.model.bidSpecification.cpmFees, function(fee) {
            return fee.type === 'MONETARY';
          });
        }
        hasMonetaryFees = hasMonetaryFees || (_.get(ctrl.model.bidSpecification, ['techFee', 'type']) || '') === 'MONETARY';
        if (!hasMonetaryFees) {
          ctrl.cpmPrefix = 'v';
        }
      } else if (_.get(ctrl.model, ['bidSpecification', 'bidStrategy']) === 'DYNAMIC') {
        ctrl.cpmPrefix = 'Max ';
      }
    };
    ctrl.updateCpmPrefix();

    ctrl.editCpmFees = function() {
      let ogFees = _.cloneDeep(ctrl.model.bidSpecification);
      let hasError = false;
      const modalInstance = $uibModal.open({
        template: cpmFeesTemplate,
        controllerAs: 'modalCtrl',
        windowClass: '',
        controller: function() {
          const modalCtrl = this;

          modalCtrl.moneyCurrency = ctrl.moneyCurrency;
          modalCtrl.uiFees = _.cloneDeep(ctrl.uiFees);
          modalCtrl.isAdOps = ctrl.isAdOps;
          modalCtrl.isPureMarketplace = LocalNetworkProfile.isPureMarketplacePlatform();
          modalCtrl.isAuction = ctrl.model.type !== 'CREATIVE_TAG';

          modalCtrl.adjustCpm = function() {
            if (!MoneyHelper.isMoney(_.get(modalCtrl, ['uiFees', 'grossCpm']))) {
              return;
            }
            modalCtrl.uiFees.netCpm = calculateNetCpm(modalCtrl.uiFees.fees, modalCtrl.uiFees.grossCpm);
            modalCtrl.feesTooHigh = _.get(modalCtrl.uiFees.netCpm, 'amount') < 0;
          };

          modalCtrl.addCpmFee = () => {
            modalCtrl.uiFees.fees = _.get(modalCtrl, ['uiFees', 'fees'], []) ?? [];
            modalCtrl.uiFees.fees.unshift({
              type: 'MONETARY'
            });
          };

          modalCtrl.addSupplementaryFee = () => {
            modalCtrl.uiFees.fees = _.get(modalCtrl, ['uiFees', 'fees'], []) ?? [];
            modalCtrl.uiFees.fees.unshift({
              id: 'supplementary-fee',
              type: 'MONETARY'
            });
          };

          modalCtrl.removeCpmFee = (cpmFee) => {
            const index = _.get(modalCtrl, ['uiFees', 'fees'], []).indexOf(cpmFee);
            if (index !== -1) {
              modalCtrl.uiFees.fees.splice(index, 1);
            }
          };

          modalCtrl.err = null;
          modalCtrl.saveCpmFees = function() {
            hasError = false;
            ctrl.model.bidSpecification.cpm = _.cloneDeep(modalCtrl.uiFees.grossCpm);
            ctrl.model.bidSpecification.cpmFees = _.cloneDeep(modalCtrl.uiFees.fees || []).filter(function(fee) {
              return fee.id !== 'tech-fee' && fee.id !== 'audience-fee' && fee.id !== 'creative-format-fee' && fee.id !== 'supplementary-fee';
            });
            ctrl.model.bidSpecification.supplementaryFees = _.cloneDeep(modalCtrl.uiFees.fees || []).filter(function(fee) {
              return fee.id === 'supplementary-fee';
            }) || [];
            ctrl.updateCpmPrefix();
            if (!ctrl.isNew) {
              adnModalLoading.applyModalLoading();
              ctrl.model.savePartial('bidSpecification', null, {ignoreProduct: ctrl.model.ignoreProduct}).then(function(respLineItem) {
                _.set(ctrl.model, 'validationWarnings', respLineItem.validationWarnings);
                _.set(ctrl.model, ['bidSpecification', 'cpmTechFee'], _.get(respLineItem, ['bidSpecification', 'cpmTechFee']));
                _.set(ctrl.model, ['bidSpecification', 'audienceFees'], _.get(respLineItem, ['bidSpecification', 'audienceFees']));
                _.set(ctrl.model, ['bidSpecification', 'supplementaryFees'], _.get(respLineItem, ['bidSpecification', 'supplementaryFees']));
                _.set(ctrl.model, ['bidSpecification', 'cpmFees'], _.get(respLineItem, ['bidSpecification', 'cpmFees']));
                ogFees = _.cloneDeep(ctrl.model.bidSpecification);
                getUiFees();
                workOutMinMaxCpm(ctrl.model.axProduct);
                adnModalLoading.removeModalLoading();
                modalInstance.close({hasResult: true});
              }, function(err) {
                modalCtrl.err = _.get(err, ['data', 'errors'], []);
                adnModalLoading.removeModalLoading();
                hasError = true;
              });
            } else {
              ctrl.editsMade();
              modalInstance.close({hasResult: true});
            }
          };
        }
      });
      modalInstance.closed.then(function() {
        if (hasError) {
          ctrl.model.bidSpecification = ogFees;
        }
      });
    };

    ctrl.removeCpmFee = (cpmFee) => {
      const index = ctrl.model.bidSpecification.cpmFees.indexOf(cpmFee);
      if (index !== -1) {
        ctrl.model.bidSpecification.cpmFees.splice(index, 1);
        ctrl.editsMade();
      }
    };

    function submitAfterAction(userState) {
      const form = $document[0].getElementById("lineItemForm");
      if (form) {
        ctrl.model.userState = userState;
        $timeout(function() {
          angular.element(form).triggerHandler('submit');
        }, 1);
      }
    }

    ctrl.newBulkCreative = function() {
      $stateParams.lineItem = ctrl.model;
      $stateParams.advertiser = ctrl.model.advertiser;
      $state.go('app.creatives.bulkupload');
    };

    ctrl.submitAxForReview = function() {
      submitAfterAction('SUBMITTED');
    };

    ctrl.stopMarketplaceLineItem = function() {
      submitAfterAction('STOPPED');
    };

    ctrl.submitPublisherReview = function() {
      if (ctrl.userStateReview === 'APPROVED') {
        submitAfterAction('APPROVED');
      } else {
        submitAfterAction('PROPOSED');
      }
    };

    ctrl.cancelProductChange = function() {
      modelComms.commMade({full: false});
      ctrl.model.axProduct = model.axProduct;
    };

    ctrl.onSaveAndNext = function() {
      const productId = _.get(ctrl, ['model', 'axProduct', 'id']);
      if (officialHighImpactSimple === productId || officialProductSimple === productId) {
        _.set(ctrl.model, ['targeting', ADN_TARGETING_TYPES.siteCountry.targets, ADN_TARGETING_TYPES.siteCountry.apiProp], ctrl.selectedCountries);
      } else if (productCreativeTag === productId) {
        _.set(ctrl.model, ['targeting', ADN_TARGETING_TYPES.siteCountry.targets, ADN_TARGETING_TYPES.siteCountry.apiProp], []);
      }
    };

    ctrl.onBeforeSubmit = function(form) {
      commons.modDates(ctrl.model.modStartDate, ctrl.model.modEndDate);
      delete ctrl.model.objectiveDelivery; // failsafe for a bug in API deserialisation

      if (axPublisherPermission) {
        delete ctrl.model.order;
      }

      if (ctrl.isLiTemplate) {
        if (ctrl.model.teamType === 'ORDER') {
          ctrl.model.teams = [];
        } else {
          ctrl.model.order = null;
        }
      }

      if (ctrl.burnRateLimitsType === 'SINGLE') {
        ctrl.model.burnRateLimits = (ctrl.model.burnRateLimits || []).slice(0, 1);
      } else if (ctrl.burnRateLimitsType === 'VARIABLE') {
        ctrl.model.burnRateLimits = (ctrl.model.burnRateLimits || []).slice(0, 4);
      } else {
        ctrl.model.burnRateLimits = [];
      }

      if (_.isDate(ctrl.model.endDate)) {
        if ((!_.isDate(model.endDate) || model.endDate.getTime() !== ctrl.model.endDate.getTime()) && moment(ctrl.model.endDate).isBefore(moment())) {
          form.clientErrors.push({withApi: true, text: "The end date must be in the future", code: 'endDate', context: 'resource.model.endDate'});
        }
      }

      if (ctrl.data) {
        _.forEach(ctrl.model.objectives, function(num, oKey) {
          const statRef = objToStatRef[oKey];
          if (!statRef) {
            return;
          }
          const alreadyDelivered = ctrl.data[statRef.stat];
          const previousNum = _.get(model, ['objectives', oKey]);
          if (_.isNumber(num) && (!_.isNumber(previousNum) || num !== previousNum) && num <= alreadyDelivered) {
            form.clientErrors.push({withApi: true, text: "The " + $filter('adnFieldTranslate')('report.field.' + statRef.trans).toLowerCase() + " objective must be more than " + adnStatFilter(alreadyDelivered), code: 'overDone' + oKey, context: 'lineItem.objectives.' + oKey});
          }
        });
      }

      _.forEach(ctrl.model.rateLimits, function(rateLimit) {
        if (rateLimit.type === 'BUDGET') {
          rateLimit.scope = 'GLOBAL';
          rateLimit.smoothed = false;
          rateLimit.periodType = 'DAYS';
        }
      });

      if (_.get(ctrl.model, ['bidSpecification', 'optimiseFor', 'eventType']) === 'CUSTOM') {
        // This is the attention event in the CALT network
        ctrl.model.bidSpecification.optimiseFor.customEventType = {id: '1q9qmf5z0b2ry1qd'};
      } else if (_.get(ctrl.model, ['bidSpecification', 'optimiseFor', 'customEventType'])) {
        ctrl.model.bidSpecification.optimiseFor.customEventType = null;
      }

      if (ctrl.model.type === 'CREATIVE_TAG' || _.get(ctrl, ['model', 'axProduct', 'type']) === 'CREATIVE_TAG' || _.get(ctrl, ['model', 'axProduct', 'id']) === productCreativeTag) {
        _.set(ctrl.model, ['bidSpecification', 'bidStrategy'], null);
        _.set(ctrl.model, ['type'], 'CREATIVE_TAG');
        const defaultCurrency = _.get(ctrl, ['model', 'team', 'defaultCurrency']);
        if (defaultCurrency && !_.get(ctrl, ['model', 'billingCurrency'])) {
          _.get(ctrl, ['model', 'billingCurrency'], defaultCurrency);
        }
      }

      // We no longer send the smoothed flag from the UI
      delete ctrl.model.smoothed;

      if (form.clientErrors.length > 0) {
        return;
      }

      if (ctrl.isSelfService) {
        delete ctrl.model.tier;
      }

      if (!_.isFinite(ctrl.model.sponsorshipPercentage)) {
        ctrl.model.sponsorshipPercentage = null;
      }

      if (!_.get(ctrl.model, ['objectives', 'BUDGET', 'amount'])) {
        _.set(ctrl.model, ['objectives', 'BUDGET'], null);
      }

      if (ctrl.isNew && ctrl.isLiTemplate) {
        LocalNetworkInfo.obtain().increment('lineItemTemplatesCount');
      }

      if (!ctrl.showCpa) {
        ctrl.model.bidSpecification.cpa = {};
      }
    };
  });

export default MODULE_NAME;

/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import translate from 'angular-translate';
import uiBootstrap from 'angular-ui-bootstrap';

import _ from "lodash";

const MODULE_NAME = "cdn-stat-comp";

angular.module(MODULE_NAME, [translate, uiBootstrap])

  .component('adnCdnStatsComponent', {
    controllerAs: 'ctrl',
    bindings: {
      model: '<',
      param: '@'
    },
    template: `
        <div ng-if="ctrl.showCdnDelivery">
          <h4 class="sectionTitle">CDN Delivery</h4>
          <div class="alert alertSimple">This is only visible to adnuntius@adnuntius.com</div>
          <p>
            <button type="button" class="btn btn-default" ng-click="ctrl.getCdnStats()">Get CDN Delivery</button>
          </p>
          <div ng-if="ctrl.cdnScope.engaged">
            <adn-promise-spinner resolved="!ctrl.cdnScope.isPending">
              <p>{{ ctrl.cdnScope.totalTeras | number: 3 }}TB / {{ ctrl.cdnScope.totalGigs | number: 3 }}GB / {{ ctrl.cdnScope.totalMegs | number: 1 }}MB of delivery</p>
              <p>$ {{ ctrl.cdnScope.price | currency : "" : 2 }} estimated cost of total delivery, based on $1 USD for 300GB</p>
            </adn-promise-spinner>
          </div>
        </div>
    `,
    controller: function(statsResource, $log, $location, localUserProfile) {
      const ctrl = this;

      const toGigs = function(num, pDivisors) {
        const divisor = Math.pow(1024, (pDivisors || 3));
        return num ? (num / divisor) : 0;
      };

      const pricePerG = 0.003333333;

      ctrl.$onInit = function() {
        ctrl.showCdnDelivery = ($location.host() === 'localhost' || localUserProfile.get("username") === "adnuntius@adnuntius.com");

        ctrl.getCdnStats = function() {
          const statsParams = {
            groupBy: 'MONTHLY'
          };
          statsParams[ctrl.param] = [ctrl.model.id];
          ctrl.cdnScope = {
            engaged: true
          };
          statsResource.getCdn(statsParams, function(stats) {
            const bytes = _.get(stats, ['totals', 'bytes']);
            const gigs = toGigs(bytes);
            ctrl.cdnScope.showTeras = true;
            if (gigs < 15) {
              ctrl.cdnScope.showTeras = false;
            }
            ctrl.cdnScope.totalMegs = toGigs(bytes, 2);
            ctrl.cdnScope.totalGigs = toGigs(bytes);
            ctrl.cdnScope.totalTeras = toGigs(bytes, 4);
            ctrl.cdnScope.error = false;

            ctrl.cdnScope.price = pricePerG * ctrl.cdnScope.totalGigs;

            ctrl.cdnScope.haveStats = true;
            ctrl.cdnScope.isPending = false;
          }, function(data) {
            $log.warn(data);
            if (data.status !== -1) {
              ctrl.cdnScope.error = true;
            }
            ctrl.cdnScope.isPending = false;
          }, ctrl.cdnScope);
        };
      };
    }
  });

export default MODULE_NAME;
/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import translate from 'angular-translate';
import uiBootstrap from 'angular-ui-bootstrap';

import _ from "lodash";
import {OBJECT_TYPE} from "../constants/object-type";

const MODULE_NAME = "co2-stat-comp";

angular.module(MODULE_NAME, [translate, uiBootstrap])

  .component('adnCo2StatsComponent', {
    controllerAs: 'ctrl',
    bindings: {
      model: '<',
      objectType: '@'
    },
    template: `
        <div ng-if="ctrl.showCo2Delivery">
          <h4 class="sectionTitle">CO<sub>2</sub> Delivery</h4>
          <div class="alert alertSimple">This is only visible to adnuntius@adnuntius.com</div>
          <p>
            <button type="button" class="btn btn-default" ng-click="ctrl.getCo2Stats()">Get CO<sub>2</sub> Delivery</button>
          </p>
          <div ng-if="ctrl.co2Scope.engaged">
            <div style="position: relative">
              <div ng-if="ctrl.error" class="clearBoth" style="min-height: 200px;">
                <p class="alert alert-warning">{{'errors.api.stats' | translate}}</p>
              </div>
              <div ng-if="ctrl.isPending && ctrl.haveStats" style="position: absolute;right: 10px; top: 40px; z-index: 10; font-size: 25px;background-color: #fff;">
                <span class="fa fa-cog fa-spin"></span>
              </div>
              <div ng-if="!ctrl.error">
                <div ng-if="!ctrl.noData">
                  <div class="clearBoth">
                    <adn-promise-spinner resolved="ctrl.haveStats"></adn-promise-spinner>
                    <div ng-if="ctrl.haveStats">
                      <highchart config="ctrl.statsChart"></highchart>
                    </div>
                  </div>
                  <p>This chart is based off delivering <strong>{{ctrl.rImpsTotals | number}} impressions</strong> using Cedara benchmarks for CO<sub>2</sub> emissions, which are as follows:</p>
                  <ul>
                    <li>Programmatic Industry: <strong>{{ctrl.allStats.programmaticIndustry.emissions | number}}g</strong> @ <strong>{{ctrl.allStats.programmaticIndustry.ratio | number: 2}}</strong> grammes per 1000 impressions</li>
                    <li>Programmatic Cedara: <strong>{{ctrl.allStats.programmaticCedara.emissions | number}}g</strong> @ <strong>{{ctrl.allStats.programmaticCedara.ratio | number: 2}}</strong> grammes per 1000 impressions</li>
                    <li>Direct Industry: <strong>{{ctrl.allStats.directIndustry.emissions | number}}g</strong> @ <strong>{{ctrl.allStats.directIndustry.ratio | number: 2}}</strong> grammes per 1000 impressions</li>
                    <li>Direct Cedara: <strong>{{ctrl.allStats.directCedara.emissions | number}}g</strong> @ <strong>{{ctrl.allStats.directCedara.ratio | number: 2}}</strong> grammes per 1000 impressions</li>
                  </ul>
                  <p>For this creative, Adnuntius delivered <strong ng-if="ctrl.allStats.adn.emissions < 2">{{ctrl.allStats.adn.emissionsRaw | number: 3}}g</strong><strong ng-if="ctrl.allStats.adn.emissions">{{ctrl.allStats.adn.emissions}}g</strong> @ <strong>{{ctrl.allStats.adn.ratio | number: 4}}</strong> grammes per 1000 impressions</p>
                </div>
                <div ng-if="!!ctrl.noData">
                  <div class="noHighchartsData">
                    {{ctrl.noData}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    `,
    controller: function(statsResource, $log, $location, localUserProfile) {
      const ctrl = this;

      ctrl.$onInit = function() {
        ctrl.showCo2Delivery = ($location.host() === 'localhost' || localUserProfile.get("username") === "adnuntius@adnuntius.com");

        let advStatsSeries = {
            categories: [''],
            title: {text: null}
          },
          createChartConfig = function() {
            return {
              chart: {
                type: 'bar',
                height: 200
              },
              yAxis: {
                title: {
                  text: 'Emissions in grammes'
                }
              },
              xAxis: advStatsSeries,
              tooltip: {
                shared: true,
                valueSuffix: 'g'
              },
              series: [],
              title: {
                text: null
              },
              credits: {
                enabled: false
              },
              accessibility: {
                enabled: false
              },
              exporting: {
                enabled: true
              }
            };
          };

        ctrl.statsChart = createChartConfig();

        ctrl.getCo2Stats = function() {
          const objectTypeEl = OBJECT_TYPE[ctrl.objectType] || OBJECT_TYPE.Creative;
          const statsParams = {groupBy: objectTypeEl.caps};
          statsParams[objectTypeEl.apiParam + "Id"] = ctrl.model.id;
          statsResource.get(statsParams, function(stats) {
            ctrl.rImpsTotals = stats.totals.rendered;

            const co2Params = _.cloneDeep(statsParams);
            co2Params.groupBy = 'MONTHLY';
            co2Params[ctrl.param] = [ctrl.model.id];
            ctrl.co2Scope = {
              engaged: true
            };
            ctrl.allStats = {
              programmaticIndustry: {
                name: 'Programmatic Standard',
                ratio: 4.24
              },
              programmaticCedara: {
                name: 'Programmatic Cedara',
                ratio: 1.27
              },
              directIndustry: {
                name: 'Direct Industry',
                ratio: 0.66
              },
              directCedara: {
                name: 'Direct Cedara',
                ratio: 0.34
              }
            };

            statsResource.getCo2(co2Params, function(stats) {
              const emissions = parseInt(stats.totals.totalEmissions);
              const ratio = emissions / ctrl.rImpsTotals;

              ctrl.statsChart.series = [];
              _.forEach(ctrl.allStats, function(val) {
                val.emissions = parseInt(val.ratio * ctrl.rImpsTotals / 1000, 10);
                ctrl.statsChart.series.push({name: val.name, data: [val.emissions]});
              });
              ctrl.allStats.adn = {
                emissions: parseInt(emissions, 10),
                emissionsRaw: emissions,
                ratio: ratio * 1000
              };
              ctrl.statsChart.series.push({name: 'This Adnuntius Creative', data: [emissions]});

              ctrl.haveStats = true;
              ctrl.isPending = false;
            }, function(data) {
              $log.warn(data);
              if (data.status !== -1) {
                ctrl.error = true;
              }
              ctrl.isPending = false;
            }, ctrl);
          });
        };
      };
    }
  });

export default MODULE_NAME;
/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import translate from 'angular-translate';
import uiBootstrap from 'angular-ui-bootstrap';
import lineItemFilterButtons from "../../line-item/line-item-filter-buttons.html";
import progressBarComponent from './progress-bar-percentage-component';

import localNetworkProfile from '../../../components/session/local-network-profile';

import template from './line-item-list-component.html';
import {getListChildren} from "./resource-list-controller";
import {OBJECT_TYPE} from "../constants/object-type";
import {lineItemBulkAction} from "../../line-item/line-item-commons";
import targetingTemplate from "../../targeting/base-targeting-editor-modal.html";
import {TargetingHelper} from "../../targeting/targeting-helper";
import _ from "lodash";
import {ADN_TARGETING_TYPES} from "../../targeting/targeting-constants";

const MODULE_NAME = "line-item-list-comp";

angular.module(MODULE_NAME, [translate, uiBootstrap, localNetworkProfile, progressBarComponent])

  .component('adnLineItemList', {
    template: template,
    controllerAs: 'ctrl',
    bindings: {
      list: '<',
      readOnly: '<readonly',
      parentObject: '@',
      parentId: '@'
    },
    controller: function($templateCache, adnListHelper, Creative, $uibModal, $filter, $translate, LineItem, LocalNetworkProfile, LocalUserPermissions, adnTimeShifter) {
      const ctrl = this;

      ctrl.$onInit = function() {
        $templateCache.put("filterButtons.html", lineItemFilterButtons);
        ctrl.list.canExpand = true;

        if (!ctrl.list.getChildren) {
          getListChildren(ctrl.list, adnListHelper, OBJECT_TYPE.LineItem, Creative);
        }

        ctrl.list.bulkTargetingAction = function() {
          $uibModal.open({
            template: targetingTemplate,
            controller: 'TargetingCtrl',
            size: 'xl',
            resolve: {
              modelLineItem: function() {
                return {};
              },
              pageType: function() {
                return "bulk";
              },
              reachEnabled: function() {
                return 'LINE_ITEM';
              },
              mandatoryTargets: function() {
              },
              overridableTargeting: function() {
              },
              availableTargets: function() {
                const selTargetIds = _.map(_.filter(ADN_TARGETING_TYPES, function(tt) {
                  return !tt.bulkBlocked;
                }), 'id');
                return _.pick(ADN_TARGETING_TYPES, selTargetIds);
              },
              targetingMode: function() {
                return null;
              },
              targeting: function() {
                return {};
              },
              isLockedDown: function() {
                return false;
              }
            }
          })
            .result.then(function(wc) {
            const newTargeting = wc.wc;
            const lineItems = _.map(ctrl.list.bulkSelection, function(v, k) {
              const obj = {id: k};
              if (wc.reset) {
                obj.targeting = newTargeting;
              } else {
                const currentTargets = TargetingHelper.getTargetsWithTargets(wc, ['wc']);
                const apiProps = _.map(currentTargets, function(ct) {
                  return ct.targets;
                });
                obj.targeting = _.pick(newTargeting, apiProps);
                obj.targeting = _.merge(v.targeting, obj.targeting);
              }
              return obj;
            });
            ctrl.list.miscBulkAction(null, {}, lineItems, LineItem, {ignoreProduct: LocalNetworkProfile.getDefaults()['defaultIgnoreMarketplaceTargeting']});
          });
        };

        if (!ctrl.list.bulkAction) {
          ctrl.list.bulkAction = lineItemBulkAction(ctrl.list, {$uibModal: $uibModal, $filter: $filter, $translate: $translate}, {}, {LocalNetworkProfile: LocalNetworkProfile, LocalUserPermissions: LocalUserPermissions, adnTimeShifter: adnTimeShifter});
        }
      };
    }
  });

export default MODULE_NAME;